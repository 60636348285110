import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/maps`}
          component={lazy(() => import(`./maps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/goal/group`}
          component={lazy(() => import(`./admin-views/goalGroups`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/goal`}
          component={lazy(() => import(`./admin-views/goals`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/payment-systems`}
          component={lazy(() => import(`./admin-views/paymentSystems`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/country`}
          component={lazy(() => import(`./admin-views/country`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/discount/count`}
          component={lazy(() => import(`./admin-views/discountCount`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/discount/days`}
          component={lazy(() => import(`./admin-views/discountDays`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faq`}
          component={lazy(() => import(`./admin-views/faq`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqV2Questions`}
          component={lazy(() => import(`./admin-views/faqV2Questions`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqV2Groups`}
          component={lazy(() => import(`./admin-views/faqV2Groups`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/rentPeriod`}
          component={lazy(() => import(`./admin-views/rentPeriod`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/metaDate`}
          component={lazy(() => import(`./admin-views/metaDate`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/seo`}
          component={lazy(() => import(`./admin-views/seo`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/tips`}
          component={lazy(() => import(`./admin-views/tips`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/advantages`}
          component={lazy(() => import(`./admin-views/advantages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reviews`}
          component={lazy(() => import(`./admin-views/reviews`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/currencies`}
          component={lazy(() => import(`./admin-views/currencies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/prices`}
          component={lazy(() => import(`./admin-views/prices`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mobile-tariff`}
          component={lazy(() => import(`./admin-views/mobile-tariff`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/date`}
          component={lazy(() => import(`./admin-views/dateFormats`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/paddle-subscription`}
          component={lazy(() => import(`./admin-views/paddleSubscription`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/basic`}
          component={lazy(() => import(`./admin-views/settings/basic`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/header`}
          component={lazy(() => import(`./admin-views/header`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/body`}
          component={lazy(() => import(`./admin-views/body`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/telegram`}
          component={lazy(() => import(`./admin-views/settings/telegram`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/whats-app`}
          component={lazy(() => import(`./admin-views/settings/whatsApp`))}
        />
        <Route
            path={`${APP_PREFIX_PATH}/settings/pipe-drive`}
            component={lazy(() => import(`./admin-views/settings/pipedrive`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/validate-email`}
          component={lazy(() =>
            import(`./admin-views/settings/validate-email`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/user-facilities`}
          component={lazy(() =>
            import(`./admin-views/settings/user-facilities`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/files`}
          component={lazy(() => import(`./admin-views/settings/files`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/type-proxies`}
          component={lazy(() => import(`./admin-views/settings/type-proxies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/ases`}
          component={lazy(() => import(`./admin-views/settings/ases`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/self/email`}
          component={lazy(() => import(`./admin-views/self`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/recaptcha`}
          component={lazy(() => import(`./admin-views/recaptcha/`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/google-analitics`}
          component={lazy(() => import(`./admin-views/google-analitics`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/crm`}
          component={lazy(() => import(`./admin-views/crm/`))}
        />
        {/* <Route
          path={`${APP_PREFIX_PATH}/mail/templates`}
          component={lazy(() => import(`./admin-views/mail`))}
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/mail/templatesV2`}
          component={lazy(() => import(`./admin-views/mailV2`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mail/spam/template`}
          component={lazy(() => import(`./admin-views/mailSpamTemplate`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mail/spam`}
          component={lazy(() => import(`./admin-views/mailSpam`))}
        />

        {/* <Route
          path={`${APP_PREFIX_PATH}/mail/test`}
          component={lazy(() => import(`./admin-views/mailTest`))}
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./admin-views/orders`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/replenishment-balance/`}
          component={lazy(() => import(`./admin-views/replenishment-balance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/projects/custom`}
          component={lazy(() => import(`./admin-views/projectsCustom`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./admin-views/users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings/create/account`}
          component={lazy(() => import(`./admin-views/create-account`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/block/payment`}
          component={lazy(() => import(`./admin-views/blockPayment`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/front/error/report`}
          component={lazy(() => import(`./admin-views/frontErrorReport`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/browser-addon`}
          component={lazy(() => import(`./admin-views/browserAddon`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/proxy-packages`}
          component={lazy(() => import(`./admin-views/proxyPackages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/web-notification/templates`}
          component={lazy(() => import(`./admin-views/webNotifications`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/orders`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
